<template>
  <section
    id="price"
    style="z-index: 2;position: relative"
    class="grey-background py-5"
  >
    <v-container>
      <v-layout row wrap align-center>
        <v-flex xs12 class="text-xs-center display-1 my-5"
          >Preisinformation</v-flex
        >
        <v-flex xs12 class="text-xs-center">
          <p>
            Terminvereinbarung nur nach telefonischer Rücksprache. Während einer
            CSIR Sitzung bin ich nicht erreichbar. Sollten Sie mich telefonisch
            nicht erreichen, rufe ich Sie zurück. Tel.Nr.: 0650 2030511 Eine
            Sitzung dauert im Durchschnitt 60 Minuten, bei Kindern und
            Säuglingen meistens kürzer. Der Preis ist bei Erwachsenen aktuell
            auf 50€ festgelegt, bei Kindern und Säuglingen wird dieser je nach
            Sitzungsdauer angepasst. Bitte beachten Sie, dass eine CSIR-Sitzung
            keines Falls einen Besuch beim Facharzt ersetzt.
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "HomePlans"
};
</script>

<style scoped></style>
