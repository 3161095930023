<template>
    <v-container id="home" fluid fill-height class="home-hero" style="max-height: 100vh;">
        <v-layout justify-center align-center column pa-5>
            <div class="hidden-lg-and-up">
                <div class="display-3 font-weight-black white--text text-center">CSIR</div>
                <div class="display-3 font-weight-black white--text text-center">Barbara Liebscher</div>
            </div>
            <div class="hidden-md-and-down">
                <div class="display-4 font-weight-black white--text text-center">CSIR</div>
                <div class="display-4 font-weight-black white--text text-center">Barbara Liebscher</div>
            </div>
            <div class="display-1 font-weight-bold white--text text-center mt-5">körperliche & emotionale Balance</div>
            <div class="display-1 font-weight-bold white--text text-center mt-5">
                <v-icon large color="white">fas fa-mobile-alt</v-icon>
                <span class="ml-4">
                    <a class="white--text" href="tel:+436502030511">06502030511</a>
                </span>
            </div>
        </v-layout>
    </v-container>
</template>

<script>
	export default {
		name: 'HomeHero'
	};
</script>

<style scoped>
    .home-hero {
        background: linear-gradient(0deg, rgba(0,0,0,0.33), rgba(0,0,0,0.33)), url('../assets/background.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
    }
</style>
