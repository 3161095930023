<template>
  <div id="app">
    <v-navigation-drawer app v-model="drawer" disable-resize-watcher>
      <scrollactive class="my-nav">
        <v-list>
          <template v-for="(item, index) in items">
            <v-list-item
              :key="index"
              :to="item.route"
              @click.native="closeDrawer"
              class="scrollactive-item">
              <v-list-item-content>
                {{ item.title }}
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`divider-${index}`"></v-divider>
          </template>
        </v-list>
      </scrollactive>
    </v-navigation-drawer>

    <v-app-bar app fixed dense color="white">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-layout class="hidden-md-and-up" style="height: 100%" align-center justify-center row>
        <v-flex lg2 pt-1 ml-5>
          <router-link class="grey--text" to="/">{{ appTitle }}</router-link>
        </v-flex>
      </v-layout>

      <v-container class="mx-auto hidden-sm-and-down" style="height: 100%">
        <v-layout style="height: 100%" align-center justify-center row>
          <v-flex lg2 pt-1 class="align-center justify-center primary--text">
            <router-link to="/">{{ appTitle }}</router-link>
          </v-flex>

          <v-flex class="lg7" py-0 height="auto">
            <scrollactive class="my-nav" active-class="tab--active" v-on:itemchanged="onNavTabChanged">
              <v-tabs hide-slider centered max="700">
                <v-tab
                  v-for="item in items"
                  :key="item.title"
                  :to="item.route"
                  class="scrollactive-item">
                  <p class="navigation-text">{{ item.title }}</p>
                </v-tab>
              </v-tabs>
            </scrollactive>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'AppNavigation',
  data() {
    return {
      appTitle: 'CSIR Barbara Liebscher',
      drawer: false,
      items: [
        { title: 'Home', route: '/' },
        { title: 'Info', route: '/#info' },
        { title: 'Über mich', route: '/#aboutMe' },
        { title: 'Preise', route: '/#price' },
        { title: 'Kontakt', route: '/#contact' }
      ]
    };
  },
  methods: {
    closeDrawer() {
      this.drawer = false;
    },
    onNavTabChanged(event, currentItem, lastActiveItem) {
      $(lastActiveItem).removeClass('v-tab--active');
      $(lastActiveItem).removeClass('tab--active');
      $(currentItem).addClass('v-tab--active');
      $(currentItem).addClass('tab--active');
    }
  }
};
</script>

<style scoped>
#app a {
  text-decoration: none;
}
.v-tab p {
  margin-bottom: 0;
}
</style>
