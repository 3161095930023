<template>
    <section
        id="aboutMe"
        style="z-index: 2;position: relative"
        class="grey-background py-5"
    >
        <v-container>
            <v-layout row wrap align-center>
                <v-flex  xs12 class="display-1 text-xs-center my-5">Über mich</v-flex>
                <v-flex xs12 sm6 lg5>
                    <v-card style="max-width: 500px">
                        <v-img
                            class="full-width"
                            src="../assets/portrait02.jpg"
                            alt="Barbara Liebscher"
                        />
                    </v-card>
                </v-flex>
                <v-flex xs12 offset-sm-1 sm5 offset-lg2 lg5>
                    <p class="subheading mt-3">
                        Mein Name ist Barbara Liebscher und ich bin 1975 geboren. Die CSIR habe ich während meiner Tätigkeit als Tagesmutter in einem Vortrag kennengelernt. Ich begann mich dafür zu interessieren,
                        entdeckte es für meine Kinder und mich und war von der Wirkungsweise immer wieder überwältigt. Als mein drittes Kind alt genug war entschied ich mich dafür selbst eine CSIR Ausbildung zu absolvieren.
                        Am 13. November 2011 habe ich diese erfolgreich abgeschlossen. Für mich ist mein Beruf besonders wertvoll und ich bin immer wieder froh, wenn ich Menschen helfen kann und Sie auf ihrem Weg ein Stück begleiten darf.
                    </p>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
	export default {
		name: 'HomeAboutMe'
	};
</script>

<style scoped>
</style>