import { render, staticRenderFns } from "./HomeWelcome.vue?vue&type=template&id=2277b52e&scoped=true"
import script from "./HomeWelcome.vue?vue&type=script&lang=js"
export * from "./HomeWelcome.vue?vue&type=script&lang=js"
import style0 from "./HomeWelcome.vue?vue&type=style&index=0&id=2277b52e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2277b52e",
  null
  
)

export default component.exports