<template>
    <section
        id="info"
        style="z-index: 2;position: relative"
        class="grey-background py-5"
    >
        <v-container>
            <v-layout column>
                <v-flex class="display-1 text-xs-center my-5">Information</v-flex>
                <v-flex>
                    <div class="headline mt-3">Geschichte</div>
                    <p class="subheading mt-3">
                        Dr. Still (1828-1917) war der Gründer der Osteopathie. Die Osteopathie ist eine ganzheitliche natürliche Regulationsmethode,
                        welche sowohl auf anatomischen und physiologischen Kenntnissen basiert als auch den „bio“ mechanischen Aspekt des menschlichen Skelettaufbaus respektiert.
                        William Garner Sutherland (1873-1954) war ein Schüler von Dr. Still und bekam als Diplomarbeit „Die Anatomie und die Funktion des Schädels“.
                        Sutherland vertiefte sich in die Beobachtung der Knochenstruktur des Schädels und die damit korrelierende Funktion des Gehirns.
                        Er kam zu dem Schluss, dass dieses System nicht fest und starr ist sondern auch Spielraum zwischen den Knochen existiert um die Lebendigkeit des Systems gewährleisten zu können. Er bezog neben dem Schädel
                        auch die Wirbelsäule und das Kreuzbein in sein Konzept mit ein.
                        Das Zentralnervensystem reicht mit den Gehirnhäuten tief in den Wirbelkanal und steuert die gesamte Funktion des Körpers. Das Ergebnis präsentierte er unter dem Namen „Cranio Sacrale Osteophatie“.
                    </p>
                </v-flex>
                <v-flex>
                    <div class="headline mt-3">Tastbefund</div>
                    <p class="subheading mt-3">
                        Als „Tastbefund“ bezeichnet man die Wahrnehmung bestimmter Microbewegungen von Knochen, Gehirn- und Rückenmarksflüssigkeit und vieles mehr durch die geschulten Hände eines Osteopathen.
                        Dieser Rhythmus überträgt sich auf den gesamten Organismus und beeinflusst die Entwicklungs- und Funktionsfähigkeit des Menschen. Ein gut funktionierender „Cranio-Rhythmus“ fördert die
                        Selbstheilungskraft, sodass sich ein Gleichgewicht zwischen Körper Geist und Seele einstellen kann. Die verschiedenen Impulssetzungen werden ausschließlich mit den Händen durchgeführt,
                        während der Klient in bequemer Kleidung tiefenentspannt auf einer Liege ruht.
                    </p>
                </v-flex>
                <v-flex>
                    <div class="headline mt-3">Anwendungsgebiete</div>
                    <p class="subheading mt-3">
                        Grundsätzlich eignet sich die CSIR für jeden Menschen in jedem Alter. Um gewisse Probleme vorzubeugen eignen sich präventive Sitzungen hervorragend.
                        Cranio bewährt sich unterstützend immer wieder bei Rückenbeschwerden, verschiedenen Arten von Migräne, nach Unfällen oder anderen traumatischen Erlebnissen, bei psychischen- & seelischen- Verarbeitungsprozessen, nach
                        Operationen, bei Kieferproblemen, vor und nach Geburten für Mutter und Kind, Konzentrationsschwächen, … oder einfach zur Tiefenentspannung.
                    </p>
                </v-flex>
                <v-flex>
                    <div class="headline mt-3">Aufklärungspflicht</div>
                    <p class="subheading mt-3">
                        Die energetische Hilfestellung dient der Wiederherstellung und Harmonisierung der körpereigenen Energiefelder,
                        sie ist aber keinerlei Ersatz für ärztliche Diagnose und Behandlung sowie für psychologische oder psychotherapeutische Untersuchung oder Behandlung.
                        Sämtliche Aussagen des Energetikers sind keine Diagnosen, sondern stellen rein energetische Zustandsbeschreibungen dar.
                        Der Kunde sollte sich für die Diagnosestellung und Therapie an seinen Arzt wenden.
                    </p>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
	export default {
		name: 'HomeDetails'
	};
</script>

<style scoped>
</style>