<template>
    <section
        id="contact"
        style="z-index: 2;position: relative"
        class="grey-background py-5"
    >
        <v-container>
            <v-layout row wrap align-center>
                <v-flex xs12 class="text-xs-center display-1 my-5">Kontakt</v-flex>
                <v-flex xs12 sm5 lg5 class="text-xs-center">
                    <p>
                        körperliche & emotionale Balance
                        <br>
                        Barbara Liebscher
                        <br>
                        3680 Persenbeug
                        <br>
                        Donaufeld 7
                    </p>
                    <div>
                        <v-icon>fas fa-mobile-alt</v-icon>
                        <span class="ml-4">
                            <a class="link" href="tel:+436502030511">06502030511</a>
                        </span>
                    </div>
                    <p class="mt-2">
                        Parkmöglichkeit direkt vor dem Haus.
                    </p>
                    <div class="mt-2">
                        <v-icon>fas fa-map-marker-alt</v-icon>
                        <span class="ml-4">
                            <a class="link" target="_blank" href="https://www.google.com/maps/search/?api=1&query=48.184190,15.088070">Auf der Karte anzeigen</a>
                        </span>
                    </div>
                </v-flex>
                <v-flex xs12 offset-sm1 sm5 offset-lg2 lg5 class="mt-2">
                    <v-card style="max-width: 375px">
                        <v-img
                            class="full-width"
                            src="../assets/home01.jpg"
                            alt="Barbara Liebscher"
                        />
                    </v-card>
                </v-flex>

            </v-layout>
        </v-container>
    </section>
</template>

<script>
	export default {
		name: 'HomePlans'
	};
</script>

<style scoped>
</style>