<template>
  <span>
    <home-hero></home-hero>
    <home-welcome></home-welcome>
    <home-details></home-details>
    <home-about-me></home-about-me>
    <home-pricing></home-pricing>
    <home-contact></home-contact>
    <v-flex class="footer ma-0" row wrap align-center justify-center>
      <span>
        Barbara Liebscher | <router-link to="/impressum">Impressum</router-link>
      </span>
    </v-flex>
  </span>
</template>

<script>
import HomeHero from "@/components/HomeHero";
import HomeWelcome from "@/components/HomeWelcome";
import HomeDetails from "@/components/HomeDetails";
import HomeAboutMe from "@/components/HomeAboutMe";
import HomePricing from "@/components/HomePricing";
import HomeContact from "@/components/HomeContact";

export default {
  name: "home",
  components: {
    HomeHero,
    HomeWelcome,
    HomeDetails,
    HomeAboutMe,
    HomePricing,
    HomeContact
  }
};
</script>

<style>
.grey-background {
  background: linear-gradient(0deg, #f5f5f5 0, #fff 50%);
}

section .container {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
}

.link {
  color: dodgerblue !important;
}

.footer {
  background-color: darkgrey;
  color: white;
  min-height: 48px;
}

.footer a {
  color: white !important;
  text-decoration: underline;
}
</style>
