import { render, staticRenderFns } from "./HomeContact.vue?vue&type=template&id=4c79674a&scoped=true"
import script from "./HomeContact.vue?vue&type=script&lang=js"
export * from "./HomeContact.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c79674a",
  null
  
)

export default component.exports