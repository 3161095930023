<template>
    <section
        style="z-index: 2;position: relative"
        class="grey-background py-5"
    >
        <v-container>
            <v-layout row wrap align-center>
                <v-flex xs12 class="display-1 text-center mb-4">Herzlich Willkommen auf meiner Website!</v-flex>
                <v-flex xs12 sm6 lg6>
                    <p>
                        Schön dass Du hier bist. In den nächsten Abschnitten erfährst Du mehr über meine Arbeit und mich.
                        Ich möchte Dich sehr gerne ein Stück auf deinem Weg begleiten.
                        Gemeinsam können wir Deine Selbstheilungskraft aktivieren und ungenutztes Potenzial wecken, damit Du ein zufriedeneres und glücklicheres Leben führen kannst.
                        Du darfst mich jederzeit gerne kontaktieren!
                    </p>
                    <p>
                        Ich würde mich freuen Dich persönlich kennenzulernen!
                    </p>
                    <p>
                        Ganz liebe Grüße,
                        <br>
                        Barbara
                    </p>
                </v-flex>
                <v-flex xs12 offset-sm1 sm5 offset-lg2 lg4 class="text-xs-center">
                    <v-card class="justify-center img-size">
                        <v-img
                            class="full-width"
                            src="../assets/woman01.jpg"
                            alt="Barbara Liebscher"
                        />
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    export default {
        name: "HomeWelcome"
    }
</script>

<style scoped>
    @media only screen and (min-width: 600px) {
        .img-size {
            max-width: 250px !important;
        }
    }
</style>